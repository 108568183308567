import * as cx from 'classnames';
import React from 'react';

import s from './Input.module.sass';

// Props
type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  theme?: string;
  label?: string;
  error?: string;
  success?: boolean;
  active?: boolean;
  successText?: string;
  required?: boolean;
  labelClassName?: string;
};

const Input: React.FC<InputProps> = ({
  theme,
  label,
  error,
  success,
  active,
  disabled,
  className,
  successText,
  labelClassName,
  ...props
}) => (
  <label
    className={cx(
      s.wrapper,
      { [s.noMargin]: theme?.includes('noMargin') },
      { [s.centered]: theme?.includes('centered') },
      { [s.minWidth]: theme?.includes('minWidth') },
      { [s.fullWidth]: theme?.includes('fullWidth') },
      { [s.widthMax]: theme?.includes('widthMax') },
      { [s.centeredContent]: theme?.includes('centeredContent') },
      { [s.h40]: theme?.includes('h40') },
      { [s.noBorder]: theme?.includes('noBorder') },
    )}
  >
    {label && <span className={cx(s.text, labelClassName)}>{label}</span>}
    <div
      className={cx(
        s.label,
        { [s.active]: active },
        { [s.errorLabel]: error },
        { [s.successLabel]: success },
        { [s.successTextLabel]: successText },
        { [s.disabled]: disabled },
        className,
      )}
    >
      <span className={s.inputWrapper}>
        <span className={s.inputFocus} />
        <input
          {...props}
          required={props.required}
          disabled={disabled}
          className={cx(s.input, { [s.h100]: theme?.includes('h-100') })}
        />
      </span>
      <span className={s.error}>{error}</span>
      <span className={s.success}>{successText}</span>
    </div>
  </label>
);

export default Input;
