import React from 'react';

import Cross from '@icons/ui-kit/Cross.svg';
import SnackBarMark from '@icons/ui-kit/snackBarMark.svg';
import SnackBarMarkNoShadow from '@icons/ui-kit/snackBarMarkNoShadow.svg';
import { useCurrentTheme, THEME } from '@src/api/local/theme';
import Button from '@ui/button/Button';

import styles from './message.module.sass';

type ToastMessageProps = {
  text: string;
  closeToast: () => void;
  Icon?: React.FC;
  hideIcon?: boolean;
};

const ToastMessage: React.FC<ToastMessageProps> = ({ closeToast, text, Icon, hideIcon }) => {
  const colorTheme = useCurrentTheme();
  const isLightTheme = colorTheme.data?.theme === THEME.light;

  let DisplayIcon = Icon;

  if (!DisplayIcon) {
    DisplayIcon = isLightTheme ? SnackBarMark : SnackBarMarkNoShadow;
  }

  return (
    <div className={styles.notificationInner}>
      <div className={styles.notificationText}>
        {!hideIcon && <DisplayIcon />}
        {text}
      </div>

      <div className={styles.notificationClose}>
        <Button theme="ternary superSmall" onClick={closeToast}>
          <Cross />
        </Button>
      </div>
    </div>
  );
};

export default ToastMessage;
