import { useTranslation } from 'next-i18next';
import React from 'react';
import { toast } from 'react-toastify';

import ExclamationMark from '@icons/exclamation-red.svg';
import ExclamationMarkDarkTheme from '@icons/exclamationRedDarkTheme.svg';
import CloseIcon from '@icons/ui-kit/Cross.svg';
import { useCurrentTheme, THEME } from '@src/api/local/theme';
import Button from '@ui/button/Button';

import styles from './notify.module.sass';

type MessageProps = {
  message?: string;
  closeToast?: any;
};

const Message: React.FC<MessageProps> = ({ message, closeToast }) => {
  const { t } = useTranslation(['billing']);

  const colorTheme = useCurrentTheme();
  const isLightTheme = colorTheme.data?.theme === THEME.light;

  return (
    <div className={styles.notificationInner}>
      <div className={styles.notificationText}>
        {isLightTheme ? <ExclamationMark /> : <ExclamationMarkDarkTheme />}
        {message || t('billing:Billing.Payment.error')}
      </div>
      <div className={styles.notificationButtons}>
        <div className={styles.notificationClose}>
          <Button theme="ternary superSmall" onClick={closeToast}>
            <CloseIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export const notify = (message?: string) => {
  toast(<Message message={message} />);
};
